import { authApi } from '@/app/api'
import Store from '@/store'

export default {
  async signIn(loginData) {
    const response = await authApi.post('/login', loginData)

    if (response.status) {
      const {
        access_token,
        user: { name },
      } = response.data

      Store.dispatch('user/setUserData', { access_token, name })

      return { status: true, message: 'Успешный вход' }
    } else {
      return { status: false, message: response.error }
    }
  },

  async signUp(registerData) {
    const response = await authApi.post('/register', registerData)

    if (response.status) {
      const {
        access_token,
        user: { name },
      } = response.data

      Store.dispatch('user/setUserData', { access_token, name })

      return { status: true, message: 'Пользователь зарегестрирован' }
    } else {
      return { status: false, message: response.error }
    }
  },

  async logOut() {
    const response = await authApi.post('/logout')

    if (response.status) {
      Store.dispatch('user/clearUserData')

      return { status: true, message: 'Пользователь вышел' }
    } else {
      return { status: false, message: response.error }
    }
  },
}
